@import '../../theme/variables';

.navigation {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  background: $color-dark-background-secondary-ski;
  padding: 1.2rem;
  height: 14rem;
  box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.25);
  z-index: 1;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-dark-background-passive-ski;
    box-shadow: 0rem 0.3rem 0.8rem #00000040;
    border: none;
    border-radius: 1.5rem;
    width: 6.5rem;
    height: 6.5rem;
    margin-bottom: 1rem;

    &:active {
      box-shadow: 0rem 0.1rem 0.3rem #00000040;
    }
  }

  &__button-icon {
    fill: $color-blue;
    width: 3.2rem;
    height: 3.5rem;

    &--disable {
      fill: $color-gray;
      width: 3.2rem;
      height: 3.5rem;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: $font-semiBold;
    font-size: $font-size-m;
    line-height: $line-height-m;
    text-align: center;
    letter-spacing: 0.075rem;
    color: $color-white;

    &--active {
      color: $color-blue;
    }

    &--disable {
      @extend .navigation__link;

      color: $color-gray;
    }
  }
}

.navigation__link--active > .navigation__button {
  background-color: $color-blue;
}

.navigation__link--active > .navigation__button > .navigation__button-icon {
  fill: $color-white;
}
