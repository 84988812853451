@import '../../theme/variables';

.webcam {
  position: relative;
  margin: 0 auto 1.5rem auto;
  width: 85.9rem;
  height: 48.3rem;
  box-shadow: 0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  opacity: 1;
  transition: ease 0.4s all;

  &--hide {
    @extend .webcam;

    opacity: 0;
    visibility: hidden;
  }

  &__button {
    display: inline-block;
    background-color: inherit;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: ease 0.4s all;

    &:hover {
      opacity: 0.7;
    }

    &--hide {
      @extend .webcam__button;

      display: none;
    }
  }

  &__button-icon {
    width: 10rem;
    height: 10rem;
    fill: #1a79d5;
  }

  &__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
  }

  &__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.28) 0%,
      rgba(255, 255, 255, 0.7) 100%
    );
    backdrop-filter: blur(30px);
    padding: 1.5rem 3rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  &__name-title {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    letter-spacing: 0.075rem;
  }

  &__name-box {
    display: flex;
    align-items: center;
  }

  &__name-dot {
    width: 2.4rem;
    height: 2.4rem;
    background-color: #66b31b;
    border-radius: 50%;
    margin-right: 1.5rem;
  }

  &__name-text {
    font-weight: $font-bold;
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 1.5rem;
    overflow: hidden;
  }
}
