@import '../../theme/variables';

span {
  font-weight: $font-bold;
}

.weather {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  margin-bottom: 3rem;
  background-color: $color-white;

  &__actual {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30%;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3rem 0 1rem 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30%;
  }

  &__icon {
    width: 10rem;
    height: 10rem;
    margin-right: 2rem;
  }

  &__iconSmall {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.5rem;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    letter-spacing: 0.075rem;
  }

  &__temperature {
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 7.2rem;
  }

  &__paragraph {
    display: flex;
    align-items: center;
    font-weight: $font-regular;
    font-size: $font-size-s;
    line-height: $line-height-s;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
