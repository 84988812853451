//----- Colors -----
$color-black: #000000;
$color-blue: #1a79d5;
$color-brown: #4f382a;
$color-gray: #85979f;
$color-green: #66b31b;
$color-orange: #f19400;
$color-red: #dd4747;
$color-white: #ffffff;

//----- Colors Light -----
$color-light-background: #ffffff;
$color-light-background-gradient: linear-gradient(162.53deg, #e9f6ff 0%, #e3f4fe 100.28%);
$color-light-stroke: #f2f2f2;
$color-light-line: #e1e1e1;
$color-light-text: #ffffff;

//----- Colors Dark -----
$color-dark-background: #242424;
$color-dark-background-secondary: #281f1a;
$color-dark-background-secondary-ski: #142840;
$color-dark-background-passive-ski: #183351;
$color-dark-background-gradient: linear-gradient(161.52deg, #281f1a 4.22%, #342e2a 99.6%);
$color-dark-stroke: #141414;
$color-dark-line: #404040;
$color-dark-text: #242424;

//----- Fonts -----

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;

$font-size-s: 1.6rem;
$font-size-m: 1.8rem;
$font-size-l: 2.4rem;
$font-size-xl: 3rem;
$font-size-xll: 3.6rem;

$line-height-s: 2.4rem;
$line-height-m: 2.7rem;
$line-height-l: 3.6rem;
$line-height-xl: 4.5rem;
$line-height-xll: 5.4rem;

//----- Margins -----

$margin-xs: 1rem;
$margin-s: 2rem;
$margin-m: 3rem;
$margin-l: 5rem;
$margin-xl: 10rem;
$margin-xll: 15rem;

//----- Paddings -----

$padding-xs: 1rem;
$padding-s: 2rem;
$padding-m: 3rem;
$padding-l: 5rem;
$padding-xl: 10rem;
$padding-xll: 15rem;

//----- Breakpoints -----

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xll: 1400px;
