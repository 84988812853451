@import '../../theme/variables';

.chart {
  width: 100%;
  padding: 3rem 0 3rem 3rem;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  background-color: $color-white;

  &__box {
    width: 100%;
    overflow-x: hidden;
  }

  &__name {
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    letter-spacing: 0.075rem;
    margin-bottom: 4rem;
  }

  &__icon {
    position: relative;
    transform: translateX(4.4rem);
    width: 5rem;
    margin-right: 4.6rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.chartBox {
  position: relative;
  min-width: 154rem;
  margin-bottom: 5rem;
}

.legendBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  align-items: flex-start;

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__line {
    width: 5.5rem;
    margin-right: 0.5rem;
    border-bottom: 0.4rem solid #323232;

    &Dot {
      @extend .legendBox__line;

      border-bottom: 0.4rem dotted #323232;
    }
  }

  &__paragraph {
    color: #323232;
    font-weight: 600;
    font-size: 1.6rem;
    margin-left: 1.5rem;
  }
}
