@import '../../theme/variables';

.station-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 4.5rem 5rem;
  background: $color-white;
  box-shadow: 0rem 0.4rem 1.2rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  margin-bottom: 1.5rem;
  color: $color-dark-text;
  text-decoration: none;

  &:active {
    box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  }

  &__logo {
    width: 10rem;
    height: 9rem;
    margin-right: 6.4rem;
  }

  &__logo-img {
    width: 100%;
    height: 100%;
    transition: ease 0.2s all;
    opacity: 1;

    &--hide {
      @extend .station-card__logo-img;

      opacity: 0;
    }
  }

  &__description-title {
    font-weight: $font-regular;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  &__description-box {
    display: flex;
  }

  &__description-box-element {
    display: flex;
    align-items: center;
    margin-right: 2.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__description-box-element-dot {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    margin-right: 1.4rem;
    background-color: #66b31b;

    &--red {
      @extend .station-card__description-box-element-dot;

      background-color: $color-red;
    }
  }

  &__description-box-element-icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.4rem;
    fill: #85979f;
  }
}
