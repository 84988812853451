@import '../../theme/variables';

.message {
  width: 100%;
  background: $color-white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  padding: 2.5rem 3.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &--hide {
    @extend .message;

    display: none;
  }

  &__box {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
  }

  &__box-icon {
    width: 9rem;
    height: 4.5rem;
    margin-right: 3rem;
  }

  &__box-text {
    font-weight: $font-regular;
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;

    &--bold {
      @extend .message__box-text;

      font-weight: $font-bold;
      margin-bottom: 1rem;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }

  &__button-btn {
    background-color: inherit;
    border: none;
    margin-right: 2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__button-btn-dot {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: $color-light-line;

    &--active {
      @extend .message__button-btn-dot;

      background-color: $color-blue;
    }
  }
}
