@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: 0.2s fadeIn forwards;
}

.fadeOut {
  animation: 0.2s fadeOut forwards;
}

.background {
  width: 100vw;
  height: 17rem;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
