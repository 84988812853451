@import '../../theme/variables';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loading-error {
  position: absolute;
  width: 100vw;
  top: 50%;
  margin-right: auto;
  margin-left: auto;
  font-weight: $font-semiBold;
  font-size: $font-size-l;
  line-height: $line-height-l;
  color: #1a79d5;
  text-align: center;
}

.landing {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 11.5rem;
  animation: 0.4s fadeIn forwards;

  &__header {
    width: 85rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5rem 0;
  }

  &__header-home {
    display: flex;
    align-items: center;
    background: inherit;
    border: none;
    outline: none;
  }

  &__header-home-content {
    font-size: $font-size-l;
    font-weight: $font-semiBold;
    color: $color-black;
  }

  &__header-home-icon {
    width: 5.4rem;
    height: 5rem;
    margin-right: 1rem;
  }

  &__header-hide-elem {
    width: 12.3rem;
    visibility: hidden;
    background-color: inherit;
  }

  &__header-placeholder {
    width: 19.3rem;
    height: 6.5rem;
    margin-right: 14rem;
    transition: ease 0.4s all;
    border-radius: 1.2rem;
    background-color: #1a79d5;
  }

  &__header-logo {
    width: 19.3rem;
    height: 6.5rem;
  }

  &__header-logo-image {
    width: 100%;
    height: 100%;
    transition: ease 0.4s all;
    opacity: 1;

    &--hide {
      @extend .landing__header-logo-image;

      opacity: 0;
    }
  }

  &__card {
    width: 85rem;
  }
}
