@import '../../theme/variables';

.header {
  position: fixed;
  top: 17rem;
  width: 100vw;
  background-color: inherit;
  box-shadow: none;
  transition: ease 0.4s all;
  z-index: 1;

  &--background {
    @extend .header;

    top: 0rem;
    background-color: $color-white;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 2.5rem;
    width: 6.5rem;
    height: 6.5rem;
    background-color: $color-white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: ease 0.4s all;
    opacity: 1;

    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  &__button-arrow {
    width: 4.5rem;
    height: 2.9rem;
    fill: #1a79d5;
  }
}

.header-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4.5rem 5rem;
  background: inherit;
  margin-bottom: 1.5rem;
  color: $color-dark-text;

  &__logo {
    width: 10rem;
    height: 9rem;
    margin-right: 6.4rem;
  }

  &__logo-img {
    width: 100%;
    height: 100%;
    transition: ease 0.2s all;
    opacity: 1;

    &--hide {
      @extend .header-card__logo-img;

      opacity: 0;
    }
  }

  &__description-title {
    font-weight: $font-regular;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    margin-bottom: 2rem;
  }

  &__description-box {
    display: flex;
  }

  &__description-box-element {
    display: flex;
    align-items: center;
    margin-right: 2.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__description-box-element-dot {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    margin-right: 1.4rem;
    background-color: #66b31b;

    &--red {
      @extend .header-card__description-box-element-dot;

      background-color: $color-red;
    }
  }

  &__description-box-element-icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.4rem;
    fill: #85979f;
  }
}
