@import '../../theme/variables';

.container {
  width: 95rem;
  margin: 19rem auto 15rem auto;
}

.news {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
