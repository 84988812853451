@import '../../theme/variables';

.slope-card {
  width: 100%;
  background: $color-white;
  border: 0.1rem solid $color-light-line;
  border-radius: 1.5rem;
  padding: 2.5rem 3.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  &__lift-title {
    display: flex;
    align-items: center;
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-m;
    letter-spacing: 0.075rem;
    margin-bottom: 2.5rem;

    &--small {
      font-weight: $font-light;
      font-size: $font-size-s;
      line-height: $line-height-s;
      letter-spacing: 0.075rem;
      text-transform: capitalize;
    }
  }

  &__lift-title-icon {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    fill: $color-blue;

    &--black {
      @extend .slope-card__lift-title-icon;

      fill: $color-black;
    }

    &--red {
      @extend .slope-card__lift-title-icon;

      fill: $color-red;
    }
  }

  &__lift-time {
    display: flex;
    align-items: center;
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;
  }

  &__lift-time-dot {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    margin-right: 1.4rem;
    background-color: #66b31b;

    &--red {
      @extend .slope-card__lift-time-dot;

      background-color: $color-red;
    }
  }

  &__lift {
    width: 40%;
  }

  &__list {
    list-style: none;
    border-left: 0.1rem solid $color-light-line;
    padding: 1rem 1rem 1rem 3.5rem;
    width: 30%;
  }

  &__list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__list-item-icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: $color-gray;
    margin-right: 1.5rem;

    &--blue {
      @extend .slope-card__list-item-icon;

      fill: $color-blue;
    }

    &--orange {
      @extend .slope-card__list-item-icon;

      fill: $color-orange;
    }
  }

  &__list-item-text {
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;

    &--bold {
      @extend .slope-card__list-item-text;

      font-weight: $font-semiBold;
    }
  }
}
