@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.app {
  animation: 0.4s fadeIn forwards;
}
