@import '../../theme/variables';

.lift-card {
  position: relative;
  width: 100%;
  min-height: 17rem;
  background: $color-white;
  border: 0.1rem solid $color-light-line;
  border-radius: 1.5rem;
  padding: 2.5rem 3.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  &__button {
    height: 4rem;
    padding-right: 2rem;
    font-family: 'Poppins', sans-serif;
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;
    font-weight: $font-semiBold;
    border: none;
    background-color: inherit;
  }

  &__button-arrow {
    width: 2rem;
    height: 1.2rem;
    margin-left: 1.5rem;
    fill: $color-blue;
  }

  &__lift {
    width: 40%;
    padding-right: 10px;
  }

  &__lift-dropdown {
    position: absolute;
    top: 4rem;
    z-index: 1;
    background-color: $color-white;
    width: 30rem;
    box-shadow: 0rem 0.6rem 2.4rem rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
    padding: 2rem;
    transition: ease 0.4s all;
    opacity: 1;

    &--hide {
      @extend .lift-card__lift-dropdown;

      opacity: 0;
      visibility: hidden;
    }
  }

  &__lift-dropdown-list {
    list-style: none;
  }

  &__lift-dropdown-list-item {
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: 0;
    }

    &--bold {
      @extend .lift-card__lift-dropdown-list-item;

      font-weight: $font-bold;
    }
  }

  &__lift-title {
    display: flex;
    align-items: center;
    font-weight: $font-semiBold;
    font-size: $font-size-l;
    line-height: $line-height-l;
    letter-spacing: 0.075rem;
    margin-bottom: 2.5rem;
  }

  &__lift-title-icon {
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    margin-right: 2rem;
    fill: #1979d4;
  }

  &__lift-time {
    position: relative;
    display: flex;
    align-items: center;
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;
  }

  &__lift-time-icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.5rem;
    fill: $color-green;

    &--red {
      @extend .lift-card__lift-time-icon;

      fill: $color-red;
    }
  }

  &__list {
    list-style: none;
    border-left: 0.1rem solid $color-light-line;
    padding: 1rem 1rem 1rem 3.5rem;
    width: 30%;
  }

  &__list-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__list-item-icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: $color-gray;
    margin-right: 1.5rem;
  }

  &__list-item-text {
    font-size: $font-size-s;
    line-height: $line-height-s;
    letter-spacing: 0.075rem;

    &--bold {
      @extend .lift-card__list-item-text;

      font-weight: $font-semiBold;
    }
  }
}
