@import '../../theme/variables';

.container {
  width: 95rem;
  margin: 19rem auto 15rem auto;
}

.loading-error {
  position: absolute;
  width: 100vw;
  top: 50%;
  margin-right: auto;
  margin-left: auto;
  font-weight: $font-semiBold;
  font-size: $font-size-l;
  line-height: $line-height-l;
  color: #1a79d5;
  text-align: center;
}
